// External modules
import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

const SkillsContainer = styled.div`
    width: 60%;
    height: auto;
    margin-bottom: 120px;
    padding: 48px 0;

    @media (max-width: 930px) {
        width: 85%;
    }
`;

const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 32px;

    @media (max-width: 700px) {
        font-size: 20px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 28px;
`;

const Links = styled.a`
    color: #347fc4;

    &:hover {
        color: #6ea5d8;
    }
`;

const Skills = (props) => {
    return (
        <SkillsContainer>
            <Fade triggerOnce>
                <Title>My Skill Set.</Title>
            </Fade>
            <Fade triggerOnce>
                <Desc>
                    As a Software Developer, I have a good command of Java, JavaScript, HTML, CSS, Python, and SQL. I have also worked with AWS Services(Connect, Lambdas, DynamoDB, S3), and Salesforce. Check out my <Links href="https://drive.google.com/file/d/1QMl7SQ2O2bBBN6su3nnZV6VwJll5n9q8/view?usp=sharing">resume</Links> for more details.
                </Desc>
            </Fade>
        </SkillsContainer>
    );
};

export default Skills;
