// Constants
import FEATURED from '../../Projects/Featured/Constants';

// Internal Components - img, svgs
import emailSubs1 from '../../../assets/email-subs-post-1.gif';
import emailSubs2 from '../../../assets/email-subs-post-2.png';
import emailSubs3 from '../../../assets/email-subs-post-3.png';
import emailSubs4 from '../../../assets/email-subs-post-4.png';
import emailSubs5 from '../../../assets/email-subs-post-5.png';
import emailSubs6 from '../../../assets/email-subs-post-6.png';

const { name, description, image, blurhash } = FEATURED[2];

const emailSubsData = {
    name,
    description,
    image,
    blurhash,
    websiteString: 'email-subs.bryanwong.tech',
    websiteLink: 'https://email-subs.bryanwong.tech/',
    githubPageString: 'github.com/BryanWZC/email-subscription-microservice',
    githubPageLink:
        'https://github.com/BryanWZC/email-subscription-microservice',
    techStackText: 'MongoDB, Express.js, React.js, Node.js',
    notableLibrariesText: 'Node-Mailjet, Validator',
    motivation: [
        {
            desc:
                "In many established websites nowadays, there is a usually a subscription modal whereby users can subscribe themselves to the site's newsletter. This project was started as a result of trying to understand that process.",
        },
    ],
    features: [
        {
            list: [
                'Sends a mock welcome email to the user upon sign up.',
                'Emails are validated via the WhoisXML API.',
                'Page renders well on the laptop, tablet and phone.',
            ],
        },
    ],
    lessonsLearned: [
        {
            desc:
                'The big challenges with this project were finding a service that can deliver emails to users and validating emails.',
        },
        {
            desc:
                'For the email delivery service, I used MailerJet as the service was free and they provide a node wrapper for their REST APIs. With their platform, I was able to construct a welcome email which will be sent to subscribers.',
        },
        {
            image: {
                src: emailSubs1,
                alt: 'Demo of subscribing to "Awesome Dogs".',
            },
        },
        {
            desc:
                'For email validations, it was a two-part process. The first part took place on the frontend whereby a regex pattern was given for the forms to prevent the user from typing random strings into the input. The second part was on the backend whereby the email strings were validated by requesting WhoisXMLAPI. The service validated emails against their records to filter out invalid and disposable emails.',
        },
        {
            desc:
                'After overcoming two those two challenges, I noticed that my success or failure page redirect was taking quite some time after I would submit an email and as a result, I went to investigate on that matter. Below is a http request log from trying to submit the same email 5 times to the service.',
        },
        {
            image: {
                src: emailSubs2,
                alt: 'Http request log after submitting an email 5 times.',
            },
        },
        {
            desc:
                'As you can see from the POST requests, it takes ~1800ms on average for the request to get through on my local server. I had a hunch that POST-ing an email should not take this long and went back into my code to find what was causing it. The culprit was the portion of my server code that handled sending an email and updating my MongoDB database when the email was submitted as shown below.',
        },
        {
            image: {
                src: emailSubs3,
                alt:
                    'MailerJet and DB update code was blocking the page redirect.',
            },
        },
        {
            desc:
                "In order to solve this, I ran the MailerJet and database update logic asynchronously from the rest of the code as both actions did not immediately impact the user and could finish independently from the service's operation. The code now looks like what you see below.",
        },
        {
            image: {
                src: emailSubs4,
                alt: 'Refactored form submission code.',
            },
        },
        {
            desc:
                'Now, only the email verification process by WhoisXMLAPI would be slowing the page redirects. However, that process is necessary to redirect the users to the success page if their email is valid or failure page if it is invalid. With this improvement, the time taken for the POST request decreased to ~620ms on average. This is an improvement in speed of ~66% than before.',
        },
        {
            image: {
                src: emailSubs5,
                alt: 'Updated http request logs after improvements.',
            },
        },
        {
            desc:
                'From the entire process, I have learned how to build my very own subscription modal. I have also gained a better understanding of async/await and how awaiting certain operations will hinder the user experience on the client. Before ending off, in case you missed out on the welcome email by "Everything Dogs", you will be able to find it below!',
        },
        {
            image: {
                src: emailSubs6,
                alt: "Awesome Dogs' welcome email",
            },
        },
        {
            desc:
                'Hope you enjoyed my journey in producing a functional email subscription modal.',
        },
    ],
    futureImplementations: [
        {
            list: [
                'Find a better service or library to handle email validations on the server side',
                'Use this project as a component in other projects that require email subscriptions',
            ],
        },
    ],
};

export default emailSubsData;
