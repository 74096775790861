// External modules
import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

// Internal modules
import AboutPic from './AboutPic';
import * as Scroll from 'react-scroll';

const OverallContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
`;

const MyselfContainer = styled.div`
    width: 60%;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 96px 0 48px 0;

    @media (max-width: 930px) {
        width: 85%;
    }
`;

const PicDescContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    flex-direction: column;
`;

const DescContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -6px;
    justify-content: flex-start;
    margin-bottom: 48px;
`;

const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 48px;

    @media (max-width: 700px) {
        font-size: 20px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 12px;
`;

const ProjectsLink = styled.a`
    color: #347fc4;

    &:hover {
        color: #6ea5d8;
    }
`;

const Myself = (props) => {
    const Element = Scroll.Element;

    return (
        <Element
            name="myself"
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
            <OverallContainer>
                <MyselfContainer>
                    <Fade>
                        <Title>A little bit about myself.</Title>
                    </Fade>
                    <PicDescContainer>
                        <DescContainer>
                            <Fade triggerOnce>
                                <Desc>
                                    Hi, I'm Bryan Wong, a software developer with a passion for improving people's lives through technological solutions. With over 2 years of experience in Java, JavaScript, HTML, CSS, and Python, I am a versatile full stack developer. I am always eager to learn new technologies and methodologies to deliver high-quality solutions that meet business needs.
                                </Desc>
                            </Fade>
                            <Fade triggerOnce>
                                <Desc>
                                    I hold certifications in AWS and have experience leveraging cloud services to build scalable and robust solutions. Additionally, I have experience working in an agile environment, conducting requirements meetings with clients, and sharing knowledge across teams.
                                </Desc>
                            </Fade>
                            <Fade triggerOnce>
                                <Desc>
                                    In my free time, I enjoy bouldering, jogging, working on fun side projects, traveling and sweets. Let's chat about technology, sports, or anything else that interests you!
                                </Desc>
                            </Fade>
                        </DescContainer>
                        <AboutPic />
                    </PicDescContainer>
                </MyselfContainer>
            </OverallContainer>
        </Element>
    );
};

export default Myself;
