// External modules
import React, { useState } from 'react';
import styled from 'styled-components';
import { Blurhash } from 'react-blurhash';
import { useSpring, animated } from 'react-spring';

// Internal modules
import Pic from '../../assets/tobemory.jpg';

const ProfileContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    top: 0;
    margin-right: 0;
    align-self: center;
`;

const TobemoryPic = styled.img`
    width: 230px;
    height: 310px;
    border-radius: 8px;
    object-fit: contain;
`;

const Desc = styled.p`
    width: 230px;
    font-size: 10px;
    text-align: center;
`;

const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1,
];
const trans = (x, y, s) =>
    `perspective(1000px) rotateX(${0}deg) rotateY(${0}deg) scale(${s})`;

const AboutPic = () => {
    const hash = 'CaD0[zoIS$WBKnofj]oz';
    const [loaded, setLoaded] = useState(false);
    const styleDisplay = !loaded ? { display: 'none' } : {};
    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 5, tension: 350, friction: 40 },
    }));
    return (
        <ProfileContainer>
            {!loaded && (
                <Blurhash
                    hash={hash}
                    width={'230px'}
                    height={'310px'}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                />
            )}
            <animated.div
                onMouseMove={({ clientX: x, clientY: y }) =>
                    set({ xys: calc(x, y) })
                }
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }}
            >
                <TobemoryPic
                    src={Pic}
                    style={styleDisplay}
                    alt="A picture of me in Tobemory"
                    onLoad={() => setLoaded(true)}
                />
                <Desc>
                    This is me in Tobermory, Ontario - enjoying
                    life.
                </Desc>
            </animated.div>
        </ProfileContainer>
    );
};

export default AboutPic;
