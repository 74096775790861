// External modules
import React, { useEffect } from 'react';
import styled from 'styled-components';

// Internal modules
import NavBar from '../NavBar/NavBar';
import Myself from './Myself';
import MyValues from './MyValues';
import Skills from './Skills';
import LetsConnect from '../LetsConnect/LetsConnect';
import ProfilePic from './ProfilePic';
import Intro from './Intro';

const MainContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const About = (props) => {
    return (
        <MainContainer>
            <NavBar />
            <ProfilePic />
            <Intro />
            <Myself />
            <MyValues />
            <Skills />
            <LetsConnect />
        </MainContainer>
    );
};

export default About;
