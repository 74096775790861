// External Modules
import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

// Internal Modules - Components
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Main from './components/Main/Main';
import BlogList from './components/Blog/BlogList';
import Projects from './components/Projects/Projects';
import FeaturedPostOne from './components/ProjectPosts/FeaturedPostOne';
import FeaturedPostTwo from './components/ProjectPosts/FeaturedPostTwo';
import FeaturedPostThree from './components/ProjectPosts/FeaturedPostThree';

// Internal modules - CSS and others
import './index.css';

// Constants
import FEATURED from './components/Projects/Featured/Constants';

const FEATURED_1_URL = `/projects/${FEATURED[0].name
    .replace(/\s/g, '-')
    .toLowerCase()}`;
const FEATURED_2_URL = `/projects/${FEATURED[1].name
    .replace(/\s/g, '-')
    .toLowerCase()}`;
const FEATURED_3_URL = `/projects/${FEATURED[2].name
    .replace(/\s/g, '-')
    .toLowerCase()}`;

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/blog" component={BlogList} />
                <Route exact path="/projects" component={Projects} />
                <Route
                    exact
                    path={FEATURED_1_URL}
                    component={FeaturedPostOne}
                />
                <Route
                    exact
                    path={FEATURED_2_URL}
                    component={FeaturedPostTwo}
                />
                <Route
                    exact
                    path={FEATURED_3_URL}
                    component={FeaturedPostThree}
                />
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
