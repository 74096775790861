// External modules
import React, { useState } from 'react';
import styled from 'styled-components';
import { Blurhash } from 'react-blurhash';
import { Link } from 'react-router-dom';

const FeaturedImageContainer = styled.div`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '40vw'};
    position: relative;
    margin-bottom: 24px;
    cursor: 'pointer';
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    background-color: rgba(0, 0, 0, 0.64);
`;

const Title = styled.h1`
    color: #fff;
    opacity: 0.8;
    font-size: 32px;
    text-align: center;

    @media (max-width: 650px) {
        font-size: 20px;
    }
`;

const FeaturedImage = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [overlayDisplay, setOverlayDisplay] = useState(false);

    const { name, image, hash, width, height } = props;
    const urlFriendlyName = name.replace(/\s/g, '-').toLowerCase();
    const styleDisplay = !loaded ? { display: 'none' } : {};
    return (
        <Link to={`/projects/${urlFriendlyName}`}>
            <FeaturedImageContainer
                onMouseOver={() => setOverlayDisplay(true)}
                onMouseLeave={() => setOverlayDisplay(false)}
                width={width}
                height={height}
            >
                {!loaded && (
                    <Blurhash
                        hash={hash}
                        width={'100%'}
                        height={'100%'}
                        resolutionX={32}
                        resolutionY={32}
                        punch={1}
                    />
                )}
                <Image
                    style={styleDisplay}
                    src={image}
                    onLoad={() => setLoaded(true)}
                />
                {overlayDisplay && (
                    <Overlay>
                        <Title>{name}</Title>
                    </Overlay>
                )}
            </FeaturedImageContainer>
        </Link>
    );
};

export default FeaturedImage;
