// External modules
import React from 'react';
import styled from 'styled-components';

// Internal modules - components
import FeaturedImage from './FeaturedImage';

// Constants
import FEATURED from './Constants';

const FeaturedContainer = styled.div`
    width: 60%;
    height: auto;
    margin-bottom: 80px;

    @media (max-width: 930px) {
        width: 85%;
        margin-bottom: 48px;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;

    @media (max-width: 650px) {
        font-size: 16px;
    }
`;

const Featured = (props) => {
    return (
        <FeaturedContainer>
            <SubTitle>Featured Projects.</SubTitle>
            {FEATURED.map((project) => (
                <FeaturedImage
                    name={project.name}
                    image={project.image}
                    hash={project.blurhash}
                    key={project.blurhash}
                />
            ))}
        </FeaturedContainer>
    );
};

export default Featured;
