// External modules
import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

const Overlay = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: rgba(142, 249, 243, 0.4);
    padding: 96px 0;
    margin-bottom: 48px;
`;

const ValuesContainer = styled.div`
    width: 60%;
    height: auto;

    @media (max-width: 930px) {
        width: 85%;
    }
`;

const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 48px;

    @media (max-width: 700px) {
        font-size: 20px;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;

    @media (max-width: 700px) {
        font-size: 16px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    margin-bottom: 48px;
    line-height: 28px;

    @media (max-width: 700px) {
        margin-bottom: 18px;
    }
`;

const MyValues = (props) => {
    return (
        <Overlay>
            <ValuesContainer>
                <Fade triggerOnce>
                    <Title>My Values.</Title>
                </Fade>
                <Fade triggerOnce>
                    <SubTitle style={{ color: '#D62839' }}>
                        Being Passionate
                    </SubTitle>
                </Fade>
                <Fade triggerOnce>
                    <Desc>
                        If you let your passions drive what you do, you will be
                        able to live out your best life. I was inspired by
                        friends and people from all walks of my life who
                        despite their circumstances, pushed through with their
                        passions and are living better lives than before.
                    </Desc>
                </Fade>
                <Fade triggerOnce>
                    <SubTitle style={{ color: '#820B8A' }}>
                        Taking Chances
                    </SubTitle>
                </Fade>
                <Fade triggerOnce>
                    <Desc>
                        Sometimes, you have to venture into uncharted
                        territories in your life and take that leap of faith.
                        Trying to transition into a new career was by no means
                        an easy decision. Wanting to see myself be in the tech 
                        industry meant that I had to take that chance. 
                        I am glad I did. Now, 2+ years into my tech career, I have the opportunity to work 
                        among a great team developing technical solutions for clients as a software developer.
                    </Desc>
                </Fade>
                <Fade triggerOnce>
                    <SubTitle style={{ color: '#F19A3E' }}>
                        Always Learning
                    </SubTitle>
                </Fade>
                <Fade triggerOnce>
                    <Desc>
                        Learning is a lifelong journey. I am always looking for
                        ways to expand my skills and knowledge through
                        completing certifications, learning new technologies and
                        industry best practices, and incorporating them at work or on
                        my fun side projects.
                    </Desc>
                </Fade>
                <Fade triggerOnce>
                    <SubTitle style={{ color: '#0B7A75' }}>
                        Staying Humble
                    </SubTitle>
                </Fade>
                <Fade triggerOnce>
                    <Desc>
                    I recognize that I will always have more to learn and improve on. 
                    While having that growth mindset, I am grateful to see how far I have 
                    progressed since I started.
                    </Desc>
                </Fade>
            </ValuesContainer>
        </Overlay>
    );
};

export default MyValues;
