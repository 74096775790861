// External modules
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';
import { Fade } from 'react-awesome-reveal';

// Internal modules
import Social from './Social';
import { ReactComponent as Next } from '../../assets/chevron-down.svg';
import { ReactComponent as NextGrey } from '../../assets/chevron-down-grey.svg';

const OverallContainer = styled.div`
    width: 100%;
    height: calc(100vh - 148px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IntroContainer = styled.div`
    min-height: 84px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: -148px;

    @media (max-width: 400px) {
        max-width: 300px;
    }
`;

const Hello = styled.h1`
    font-size: 42px;
    margin-bottom: 8px;
    color: #fbfbff;
    text-align: center;

    @media (max-width: 800px) {
        font-size: 30px;
    }

    @media (max-width: 400px) {
        font-size: 24px;
    }
`;

const Work = styled.p`
    font-size: 20px;
    color: #fbfbff;
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: 800px) {
        font-size: 12px;
    }

    @media (max-width: 400px) {
        font-size: 8px;
    }
`;

const ReadMoreContainer = styled.div`
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Text = styled.p`
    font-size: 16px;
    color: ${(props) => (props.hoverChev ? '#fbfbff' : 'transparent')};

    @media (max-width: 800px) {
        display: none;
    }
`;

const Intro = (props) => {
    const scroller = Scroll.scroller;
    const [hoverChev, setHoverChev] = useState(false);

    return (
        <OverallContainer>
            <IntroContainer>
                <Fade delay={300}>
                    <Hello>Hi, I'm Bryan Wong.</Hello>
                </Fade>
                <Fade delay={300}>
                    <Work>
                        I am a software developer with 2+ years of experience with JavaScript, Java, HTML, CSS, and Python.
                    </Work>
                </Fade>
                <Social type="main" />
            </IntroContainer>
            <ReadMoreContainer
                onClick={() => {
                    scroller.scrollTo('myself', {
                        duration: 1500,
                        delay: 100,
                        smooth: true,
                        offset: 1,
                    });
                }}
                onMouseOver={() => setHoverChev(true)}
                onMouseOut={() => setHoverChev(false)}
            >
                <Text hoverChev={hoverChev}>
                    Check out what I'm looking at!
                </Text>
                {hoverChev ? (
                    <NextGrey
                        width={'64px'}
                        height={'64px'}
                        stroke={'#999999'}
                        id={'next'}
                        onMouseOver={() => setHoverChev(true)}
                        style={{ pointerEvents: 'none' }}
                    ></NextGrey>
                ) : (
                    <Next
                        width={'64px'}
                        height={'64px'}
                        stroke={'#999999'}
                        id={'next'}
                        onMouseOver={() => setHoverChev(true)}
                        style={{ pointerEvents: 'none' }}
                    ></Next>
                )}
            </ReadMoreContainer>
        </OverallContainer>
    );
};

export default Intro;
