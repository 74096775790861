// Constants
import FEATURED from '../../Projects/Featured/Constants';

// Internal Components - img, svgs
import bibliaPost1 from '../../../assets/biblia-post-1.png';
import bibliaPost2 from '../../../assets/biblia-post-2.png';
import bibliaPost3 from '../../../assets/biblia-post-3.gif';
import bibliaPost4 from '../../../assets/biblia-post-4.gif';
import bibliaPost5 from '../../../assets/biblia-post-5.gif';

const { name, description, image, blurhash } = FEATURED[1];

const bibliaData = {
    name,
    description,
    image,
    blurhash,
    websiteString: 'biblia.bryanwong.tech',
    websiteLink: 'https://biblia.bryanwong.tech',
    githubPageString: 'github.com/BryanWZC/Biblia-API',
    githubPageLink: 'https://github.com/BryanWZC/Biblia-API',
    techStackText: 'Express.js, React.js, Node.js',
    notableLibrariesText: 'Cheerio, Got, PDFKit',
    motivation: [
        {
            desc:
                'I got interested in the concepts of web scrappers and wanted to apply it to one of my projects. There were many options as to what I could web scrape but I decided upon web scraping a online bible platform upon seeing that one of the sites, "www.bible.com", had a rather clean HTML layout to work from. Also, I wanted to create an API that could fetch different text-based results based on user query.',
        },
    ],
    features: [
        {
            list: [
                'Returns results of verses and chapters in JSON format based on different endpoints.',
                'Provides an option to output chapters in PDF format.',
                'Supports different bible translations.',
            ],
        },
    ],
    lessonsLearned: [
        {
            desc:
                'This project allowed me to understand how to filter data obtained from a source, in this case from the results of web scraping the bible platform, and output JSON data that developers can use. The API had 3 methods which are bible, bibleChapters and biblePDF.',
        },
        {
            image: {
                src: bibliaPost1,
                alt: 'The 3 methods available for the Biblia API',
            },
        },
        {
            desc:
                'The biblePDF method was the most interesting feature that I got to work on in this project as the feature that would return results of chapters queried and cleanly format it into a PDF. The library, "PDFKit", was used to produce my PDF results. It was which was straight forward to implement, however, I was faced with an issue of sending the PDF without having to save it on the server first. After struggling with this problem for a while, I found that the solution was just to send the PDF as an attachment on the server side and pipe the results from the web scraping and PDFKit to "res".',
        },
        {
            desc:
                'Now, why would piping to "res" work at all? At the time, this was quite baffling to me as I always thought that "res" was just an object. Upon careful inspection, however, I was able to find that it extended the functionalities of node.js\'s "http.ServerResponse" which extends the "streams" module. With this knowledge, we can see why piping to "res" is totally valid as it is a writable stream.',
        },
        {
            image: {
                src: bibliaPost2,
                alt:
                    'Code snippet from server.js showing that piping to res is totally valid as it is a writable stream',
            },
        },
        {
            desc:
                'Overall, I enjoyed the process of this project and learned more about APIs and node streams. See the GIFs below for the functionality of each API method.',
        },
        {
            image: {
                src: bibliaPost3,
                alt:
                    'The bible method returns results of any range of verses from a chapter of a book',
            },
        },
        {
            image: {
                src: bibliaPost4,
                alt:
                    'The bibleChapters method returns results of a chapter of a book',
            },
        },
        {
            image: {
                src: bibliaPost5,
                alt:
                    'The biblePDF method returns a PDF of a chapter of a book. Above is an example of the PDF result.',
            },
        },
    ],
    futureImplementations: [
        {
            list: [
                'More support for other languages/ translations.',
                'Implement more methods such as returning word searches or counts within queried verses.',
                'Create a user-friendly UI for non-developers to query and access results.',
            ],
        },
    ],
};

export default bibliaData;
