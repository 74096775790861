// External modules
import React from 'react';
import styled from 'styled-components';
import NavBar from '../NavBar/NavBar';

const ListContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoticeContainer = styled.div`
    width: 60%;

    @media (max-width: 800px) {
        width: 85%;
    }
`;

const Notice = styled.p`
    font-size: 14px;
`;

const BlogList = (props) => {
    return (
        <ListContainer>
            <NavBar />
            <NoticeContainer>
                <Notice>
                    Coming soon! Just need to set up the db and CMS...
                </Notice>
            </NoticeContainer>
        </ListContainer>
    );
};

export default BlogList;
