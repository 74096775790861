// External modules
import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

import { ReactComponent as Github } from '../../assets/github.svg';
import { ReactComponent as Email } from '../../assets/email.svg';
import { ReactComponent as LinkedIn } from '../../assets/linkedin.svg';

const SocialContainer = styled.div`
    width: ${(props) => props.containerWidth || '100px'};
    display: flex;
    justify-content: space-between;
`;

const Social = (props) => {
    let svgDim = props.dim || '20px';
    let fill = props.fill || '#FBFBFF';
    let trigger = false || props.trigger;
    return (
        <SocialContainer containerWidth={props.containerWidth}>
            <Fade delay={300} triggerOnce={trigger}>
                <a
                    href="https://github.com/BryanWZC"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Github
                        width={svgDim}
                        height={svgDim}
                        fill={fill}
                        className={props.type}
                    />
                </a>
            </Fade>
            <Fade delay={300} triggerOnce={trigger}>
                <a href="mailto:bryanwzc@outlook.com">
                    <Email
                        width={svgDim}
                        height={svgDim}
                        fill={fill}
                        className={props.type}
                    />
                </a>
            </Fade>
            <Fade delay={300} triggerOnce={trigger}>
                <a
                    href="https://www.linkedin.com/in/bryanwong-ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LinkedIn
                        width={svgDim}
                        height={svgDim}
                        fill={fill}
                        className={props.type}
                    />
                </a>
            </Fade>
        </SocialContainer>
    );
};

export default Social;
