// External modules
import React from 'react';
import styled from 'styled-components';

// Internal modules - components
import LetsConnect from '../LetsConnect/LetsConnect';
import NavBar from '../NavBar/NavBar';
import IntroSection from './IntroSection';
import ContentSection from './ContentSection';

// Connected data
import emailSubsData from './PostData/emailSubsData';

const PostThreeContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FeaturedPostThree = (props) => {
    return (
        <PostThreeContainer>
            <NavBar />
            <IntroSection data={emailSubsData} />
            <ContentSection data={emailSubsData} />
            <LetsConnect />
        </PostThreeContainer>
    );
};

export default FeaturedPostThree;
