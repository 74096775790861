// Internal modules - Featured images
import yelloMockup from '../../../assets/yello-mockup.png';
import bibliaMockup from '../../../assets/biblia-mockup.png';
import emailSubsMockup from '../../../assets/email-subs-mockup.png';

// Constants
const FEATURED = [
    {
        name: 'Yello',
        description:
            'A Kaban-style task tracking board with drag-and-drop functionalities.',
        image: yelloMockup,
        blurhash: 'bhMHMjxut7s:~pWBaKRj?HRj',
    },
    {
        name: 'Biblia API',
        description:
            'A bible API that returns search results of verses in JSON format based on search parameters given. Also, the results can be extracted onto a PDF, to be downloaded and saved.',
        image: bibliaMockup,
        blurhash: 'LIQHb4w]y@xao0WVo0j[.nWqYkWB',
    },
    {
        name: 'Email Subscription Microservice',
        description:
            'Email subscription modal that sends an email to you when you subscribe and stores the email in a database.',
        image: emailSubsMockup,
        blurhash: 'CHEpp0IT00?w${jYIrXA',
    },
];

export default FEATURED;
