// External modules
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavContainer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
`;

const TotalContainer = styled.div`
    width: 60%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 930px) {
        width: 85%;
        height: 80px;
    }
`;

const NameContainer = styled.div``;

const Name = styled.p`
    font-size: 24px;
    cursor: pointer;
    color: #242331;

    &:hover {
        color: #999999;
    }

    @media (max-width: 950px) {
        font-size: 20px;
    }

    @media (max-width: 475px) {
        font-size: 18px;
    }
`;

const LinksContainer = styled.div`
    width: 320px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 950px) {
        width: 250px;
    }

    @media (max-width: 475px) {
        width: 200px;
    }
`;

const LinkBtn = styled.button`
    font-size: 20px;
    height: auto;

    &:hover {
        color: #999999;
    }

    @media (max-width: 950px) {
        font-size: 14px;
    }

    @media (max-width: 475px) {
        font-size: 12px;
    }
`;

const NavBar = (props) => {
    return (
        <NavContainer>
            <TotalContainer>
                <NameContainer>
                    <Link to="/">
                        <Name>
                            {window.screen.width >= 550 ? 'Bryan Wong' : 'BW'}
                        </Name>
                    </Link>
                </NameContainer>
                <LinksContainer>
                    <Link to="/">
                        <LinkBtn>Home</LinkBtn>
                    </Link>
                    <a href="https://drive.google.com/file/d/1QMl7SQ2O2bBBN6su3nnZV6VwJll5n9q8/view?usp=sharing">
                        <LinkBtn>Resume</LinkBtn>
                    </a>
                    <Link to="/projects">
                        <LinkBtn>Projects</LinkBtn>
                    </Link>
                </LinksContainer>
            </TotalContainer>
        </NavContainer>
    );
};

export default NavBar;
