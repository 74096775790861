// External modules
import React from 'react';
import styled from 'styled-components';

// Internal modules - components
import NavBar from '../NavBar/NavBar';
import Intro from './Intro';
import Featured from './Featured/Featured';
import Recent from './Recent/Recent';
import LetsConnect from '../LetsConnect/LetsConnect';

const ProjectsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Projects = (props) => {
    return (
        <ProjectsContainer>
            <NavBar />
            <Intro />
            <Featured />
            {/*<Recent />*/}
            <LetsConnect />
        </ProjectsContainer>
    );
};

export default Projects;
