// External modules
import React from 'react';
import styled from 'styled-components';
import RecentProjects from './RecentProjects';

const RecentContainer = styled.div`
    width: 60%;
    height: auto;
    margin-bottom: 80px;

    @media (max-width: 930px) {
        width: 85%;
        margin-bottom: 48px;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;

    @media (max-width: 650px) {
        font-size: 16px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 12px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

const Recent = (props) => {
    return (
        <RecentContainer>
            <SubTitle>Recent projects.</SubTitle>
            <Desc>
                Below you will find my most recent projects fetched off Github
                and listed in descending chronological order.
            </Desc>
            <RecentProjects />
        </RecentContainer>
    );
};

export default Recent;
