// External modules
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const RecentProjectsContainer = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 48px;
`;

const Container = styled.div`
    width: 100%;
    margin-bottom: 24px;
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 28px;

    &:nth-child(1) {
        display: inline;
        font-weight: bold;
        color: #347fc4;
    }

    &:nth-child(1):hover {
        color: #6ea5d8;
    }
`;

const RecentProjects = (props) => {
    let [repo, setRepo] = useState(null);
    let isArray = Array.isArray(repo);

    useEffect(() => {
        axios.get('/github/latest').then((res) => setRepo(res.data));
    }, [isArray]);

    return (
        <RecentProjectsContainer>
            {repo &&
                repo.map((project) => {
                    return (
                        <Container>
                            <a
                                href={project.html_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Desc>{project.name}</Desc>
                            </a>
                            <Desc>{project.description}</Desc>
                        </Container>
                    );
                })}
        </RecentProjectsContainer>
    );
};

export default RecentProjects;
