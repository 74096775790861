// External modules
import React from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';

const ContentDesc = styled.p`
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 24px;
`;

const List = styled.ul`
    list-style-position: inside;
    margin-bottom: 24px;
`;

const ListItem = styled.li`
    font-size: 14px;
    line-height: 28px;
`;

const PostImage = styled.img`
    width: 60%;

    @media (max-width: 650px) {
        width: 100%;
    }
`;

const ImageDesc = styled.p`
    font-size: 10px;
    margin-bottom: 24px;
    width: 60%;

    @media (max-width: 650px) {
        width: 100%;
    }
`;

export function ContentFromData({ data }) {
    return data.map((section) => {
        if (section.desc)
            return <ContentDesc key={nanoid(10)}>{section.desc}</ContentDesc>;
        if (section.list)
            return (
                <List key={nanoid(10)}>
                    {section.list.map((point) => (
                        <ListItem key={nanoid(10)}>{point}</ListItem>
                    ))}
                </List>
            );
        if (section.image)
            return (
                <React.Fragment key={nanoid(10)}>
                    <PostImage
                        src={section.image.src}
                        alt={section.image.alt}
                    />
                    <ImageDesc>{section.image.alt}</ImageDesc>
                </React.Fragment>
            );

        throw new Error(
            'You did not set a desc, list or image properties in your data section object or you did set those properties and they are undefined or empty'
        );
    });
}

export default ContentFromData;
