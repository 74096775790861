// External modules
import React from 'react';
import styled from 'styled-components';

import ContentFromData from './ContentFromData';

const ContentContainer = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;

    @media (max-width: 930px) {
        margin-bottom: 48px;
        width: 85%;
    }
`;

const SubTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;

    @media (max-width: 650px) {
        font-size: 16px;
    }
`;

const ContentSection = (props) => {
    const {
        motivation,
        features,
        lessonsLearned,
        futureImplementations,
    } = props.data;
    return (
        <ContentContainer>
            <SubTitle>Motivation:</SubTitle>
            <ContentFromData data={motivation} />

            <SubTitle>Features:</SubTitle>
            <ContentFromData data={features} />

            <SubTitle>Lessons Learned:</SubTitle>
            <ContentFromData data={lessonsLearned} />

            <SubTitle>Future Implementations:</SubTitle>
            <ContentFromData data={futureImplementations} />
        </ContentContainer>
    );
};

export default ContentSection;
