// External modules
import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

// Internal modules
import Social from '../Main/Social';

const ConnectContainer = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;

    @media (max-width: 930px) {
        width: 85%;
    }
`;

const Title = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;

    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

const Desc = styled.p`
    font-size: 12px;
    margin-bottom: 32px;
    text-align: center;
`;

const LetsConnect = (app) => {
    return (
        <ConnectContainer>
            <Fade triggerOnce>
                <Title>Let's Connect!</Title>
            </Fade>
            <Fade triggerOnce>
                <Desc>Get in touch for opportunities or just to say hi! 👋</Desc>
            </Fade>
            <Social
                containerWidth={'120px'}
                dim={'24px'}
                fill={'#333333'}
                type={'about'}
                trigger={true}
            />
        </ConnectContainer>
    );
};

export default LetsConnect;
