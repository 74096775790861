// External modules
import React from 'react';
import styled from 'styled-components';

const IntroContainer = styled.div`
    width: 60%;
    height: auto;
    margin-bottom: 80px;

    @media (max-width: 930px) {
        margin-bottom: 48px;
        width: 85%;
    }
`;

const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;

    @media (max-width: 650px) {
        font-size: 20px;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 12px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

const GithubLink = styled.a`
    color: #347fc4;

    &:hover {
        color: #6ea5d8;
    }
`;

const Intro = (props) => {
    return (
        <IntroContainer>
            <Title>My Projects.</Title>
            <Desc>
                Below you will find my 3 featured projects. You will also find a list of all my
                projects from my{' '}
                <GithubLink
                    href="https://github.com/BryanWZC"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    github
                </GithubLink>{' '}
                repository.{' '}
            </Desc>
        </IntroContainer>
    );
};

export default Intro;
