// External modules
import React from 'react';
import styled from 'styled-components';

// Internal modules - components
import LetsConnect from '../LetsConnect/LetsConnect';
import NavBar from '../NavBar/NavBar';
import IntroSection from './IntroSection';
import ContentSection from './ContentSection';

// Connected data
import bilbiaData from './PostData/bibliaData';

const PostTwoContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FeaturedPostTwo = (props) => {
    return (
        <PostTwoContainer>
            <NavBar />
            <IntroSection data={bilbiaData} />
            <ContentSection data={bilbiaData} />
            <LetsConnect />
        </PostTwoContainer>
    );
};

export default FeaturedPostTwo;
