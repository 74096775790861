// External modules
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Internal modules
import PicSky from '../../assets/hero-profile-sky.png';
import PicLand1 from '../../assets/hero-profile-landscape-1.png';
import PicLand2 from '../../assets/hero-profile-landscape-2.png';
import PicLand3 from '../../assets/hero-profile-landscape-3.png';
import PicLand4 from '../../assets/hero-profile-landscape-4.png';
import PicLand5 from '../../assets/hero-profile-landscape-5.png';
import PicPerson from '../../assets/hero-profile-person.png';

const ProfileContainer = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    overflow: hidden;
`;

const Sky = styled.div`
    height: 100%;
    width: 100%;
    background: url(${PicSky});
    background-size: cover;
    background-position: left 40%;
    background-attachment: fixed;
    position: absolute;
    z-index: -1;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }
`;

const Landscape1 = styled.div`
    height: 100%;
    width: 100%;
    background: url(${PicLand1});
    background-size: cover;
    background-position: left 40%;
    background-attachment: fixed;
    position: absolute;
    z-index: 10;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Landscape2 = styled.div`
    height: 100%;
    width: 100%;
    background: url(${PicLand2});
    background-size: cover;
    background-position: left 40%;
    background-attachment: fixed;
    position: absolute;
    animation: ${fadeIn} 0.5s ease-in;
    z-index: 9;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }
`;

const Landscape3 = styled.div`
    height: 100%;
    width: 100%;
    background: url(${PicLand3});
    background-size: cover;
    background-position: left 40%;
    background-attachment: fixed;
    position: absolute;
    animation: ${fadeIn} 1.5s ease-in;
    z-index: 8;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }
`;

const Landscape4 = styled.div`
    height: 100%;
    width: 100%;
    background: url(${PicLand4});
    background-size: cover;
    background-position: left 40%;
    background-attachment: fixed;
    position: absolute;
    animation: ${fadeIn} 2.5s ease-in;
    z-index: 7;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }
`;

const Landscape5 = styled.div`
    height: 100%;
    width: 100%;
    background: url(${PicLand5});
    background-size: cover;
    background-position: left 40%;
    background-attachment: fixed;
    position: absolute;
    animation: ${fadeIn} 3.5s ease-in;
    z-index: 6;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }
`;

const Hero = styled.div`
    height: 100%;
    width: 100%;
    background: url(${PicPerson});
    background-size: cover;
    background-position: left 40%;
    background-attachment: fixed;
    position: absolute;
    z-index: 11;

    @media (max-width: 1366px) {
        background-attachment: scroll;
    }
`;

const ProfilePic = (props) => {
    return (
        <ProfileContainer>
            <Sky />
            <Landscape1 />
            <Landscape2 />
            <Landscape3 />
            <Landscape4 />
            <Landscape5 />
            <Hero />
        </ProfileContainer>
    );
};

export default ProfilePic;
