// Constants
import FEATURED from '../../Projects/Featured/Constants';

// Internal Components - img, svgs
import yelloPost1 from '../../../assets/yello-post-1.png';
import yelloPost2 from '../../../assets/yello-post-2.png';
import yelloPost3 from '../../../assets/yello-post-3.gif';

const { name, description, image, blurhash } = FEATURED[0];

const yelloData = {
    name,
    description,
    image,
    blurhash,
    websiteString: 'yello.bryanwong.tech',
    websiteLink: 'https://yello.bryanwong.tech',
    githubPageString: 'github.com/BryanWZC/Yello',
    githubPageLink: 'https://github.com/BryanWZC/Yello',
    techStackText: 'MongoDB, Express.js, React.js, Node.js',
    notableLibrariesText:
        'Redux.js, React-Beautiful-DND, Styled-components, Blurhash',
    motivation: [
        {
            desc:
                'I wanted to create a task tracking board that would enable me to keep track of my programming projects. From the design and functionality of the app, one could easily tell that it was greatly inspired by Trello. This was intentional as I wanted users to feel a sense of familiarity when using the app.',
        },
    ],
    features: [
        {
            list: [
                'Allows for dragging and dropping of cards and list items.',
                'Allows for content updates within each list item.',
                'Background image selection using Unsplash API with blurhash implemented.',
            ],
        },
    ],
    lessonsLearned: [
        {
            desc:
                'The two most memorable takeaways from this project would be Redux state management and image placeholders with BlurHash.',
        },
        {
            desc:
                'For state management, I started off with using useState() and hoisted all my state to the closest common ancestors as recommended by React. However, I soon encountered having to pass my props down several levels and was constrained by only being allowed to declare functions that set state within the parent component. After completing the app given this style of state management, my code base did not look as clean as I would like it to even though I followed best practices thus far.',
        },
        {
            image: {
                src: yelloPost1,
                alt:
                    'Code snippet from the parent component showing props chain starting at card component which will have to be passed to the lowest component level.',
            },
        },
        {
            desc:
                "As a result, I started to learn about redux and found it's single source of truth principle just what I needed. Here are the reasons why Redux was the right decision for state management:",
        },
        {
            list: [
                "Redux being the 'single source of truth' allowed me to connect all my state in a single store making it easier to manage it.",
                'Eliminates the problem of sending props down several levels to get to target component.',
                'Code base for components is much cleaner and maintainable which allows for the app to scale.',
                'Redux Toolkit allows for redux functionality to be written in less lines of code making adopting Redux less of a barrier than before.',
            ],
        },
        {
            image: {
                src: yelloPost2,
                alt: 'Much cleaner and maintainable code thanks to Redux!',
            },
        },
        {
            desc:
                "For image placeholders, I only came to realize it's importance when I noticed in the Unsplash API documentation that each image requested came with a BlurHash string. What BlurHash aims to do is to provide vibrant placeholder images while the actual image is still loading. Since I wanted to serve high quality image as the background as a high quality background image increases the user experience of the app, BlurHash was exactly what I needed. It lets users know that another image is rendering instead of just having the image load abruptly after a relatively long time(due to it being high quality).",
        },
        {
            image: {
                src: yelloPost3,
                alt: 'Usage of BlurHash within the project.',
            },
        },
        {
            desc:
                'While there are other takeaways that I got from this project such as my experience with React-Beautiful-dnd and reorganizing my folders in anticipation of app scaling, I will save that for another time. Overall, I was satisfied with what I have accomplished and cannot wait to add more features to this app in the future.',
        },
    ],
    futureImplementations: [
        {
            list: [
                'Create a user authentication page.',
                'Include a boards page whereby many boards can be added.',
                "Enable 'USER' mode which in code, allows to save board interactions to the database. 'GUEST' mode will still be maintained for those who do not wish to sign in.",
                'Markdown support for content within the list items.',
            ],
        },
    ],
};

export default yelloData;
