// External modules
import React from 'react';
import styled from 'styled-components';

// Internal Components - img, svgs
import link from '../../assets/link.svg';
import githubSVG from '../../assets/github-small.svg';

// Constants
import FeaturedImage from '../Projects/Featured/FeaturedImage';

const IntroContainer = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    margin-bottom: 24px;

    @media (max-width: 1150px) {
        flex-direction: column;
        margin-bottom: 0;
    }

    @media (max-width: 930px) {
        width: 85%;
    }
`;

const IntroImageContainer = styled.div`
    max-width: 400px;
    height: 300px;
    pointer-events: none;

    @media (max-width: 1150px) {
        order: 2;
        max-width: 100%;
        height: auto;
    }
`;

const Title = styled.p`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;

    @media (max-width: 650px) {
        font-size: 20px;
    }
`;

const Intro = styled.div`
    width: 100%;
    margin-left: 24px;

    @media (max-width: 1150px) {
        margin: 0 0 24px 0;
    }
`;

const Desc = styled.p`
    font-size: 14px;
    line-height: 28px;

    &:nth-child(1) {
        color: #347fc4;
    }

    &:nth-child(1):hover {
        color: #6ea5d8;
    }

    &:nth-child(4) {
        margin-top: 12px;
    }
`;

const LinkTag = styled.img``;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Website = styled.a`
    margin-left: 8px;
`;

const IntroSection = (props) => {
    const {
        name,
        description,
        image,
        blurhash,
        websiteString,
        websiteLink,
        githubPageString,
        githubPageLink,
        techStackText,
        notableLibrariesText,
    } = props.data;
    return (
        <IntroContainer>
            <IntroImageContainer>
                <FeaturedImage
                    name={name}
                    image={image}
                    hash={blurhash}
                    width={'100%'}
                    height={'100%'}
                />
            </IntroImageContainer>
            <Intro>
                <Title>{name}</Title>
                <Desc>{description}</Desc>
                {/*<LinkContainer>
                    <LinkTag src={link} alt="link to webpage svg" />
                    <Website href={websiteLink}>
                        <Desc>{websiteString}</Desc>
                    </Website>
    </LinkContainer>*/}
                <LinkContainer>
                    <LinkTag
                        src={githubSVG}
                        style={{ width: '14px', height: '14px', fill: '#fff' }}
                        alt="link to github svg"
                    />
                    <Website href={githubPageLink}>
                        <Desc>{githubPageString}</Desc>
                    </Website>
                </LinkContainer>
                <Desc>
                    <strong>Technology Stack:</strong>&nbsp; {techStackText}
                </Desc>
                <Desc>
                    <strong>Notable Libraries:</strong>&nbsp;&nbsp;&nbsp;
                    {notableLibrariesText}
                </Desc>
            </Intro>
        </IntroContainer>
    );
};

export default IntroSection;
