// External modules
import React from 'react';
import styled from 'styled-components';

// Internal modules - components
import LetsConnect from '../LetsConnect/LetsConnect';
import NavBar from '../NavBar/NavBar';
import IntroSection from './IntroSection';
import ContentSection from './ContentSection';

// Connected data
import yelloData from './PostData/yelloData';

const PostOneContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FeaturedPostOne = (props) => {
    return (
        <PostOneContainer>
            <NavBar />
            <IntroSection data={yelloData} />
            <ContentSection data={yelloData} />
            <LetsConnect />
        </PostOneContainer>
    );
};

export default FeaturedPostOne;
